import React from 'react'
import { Layout, UserContext } from '../../components/Layout'
import { Grid, Cell } from 'baseui/layout-grid'
import { getQueryVariable, handleErrorFromAPI, loginRedirect } from '../../utils'
import { ErrorNotification } from '../../components/Notification'
import { Link, navigate } from 'gatsby'
import { CategoryItem, getCategories, CategoryListingResponse } from '../../api/checklists'
import { WindowLocation } from '@reach/router'
import { Title } from '../../components/Title'
import { useStyletron } from 'baseui'
import { ourColors } from '../../components/Colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTv,
  faUserNurse,
  faUsers,
  faComment,
  faHospitalAlt,
  faBalanceScale,
  faFileAlt,
  faBriefcaseMedical
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
  faTv,
  faUserNurse,
  faUsers,
  faComment,
  faHospitalAlt,
  faBalanceScale,
  faFileAlt,
  faBriefcaseMedical
)

interface CategoryBoxProps {
  category: CategoryItem
  planId: string
}

const CategoryBox = ({ category, planId }: CategoryBoxProps) => {
  const [css, theme] = useStyletron()

  const progressionColorMap = {
    High: ourColors.scaleRed,
    Reduced: ourColors.scaleAmber,
    Low: ourColors.scaleGreen
  }

  const copyColor = category.custom_panel_color
    ? `#${category.custom_panel_color}`
    : ourColors.copyGrey
  const borderColor = category.custom_panel_color
    ? `#${category.custom_panel_color}`
    : ourColors.interfaceGrey

  return (
    <div
      className={css({
        border: `solid 1px ${borderColor}`,
        borderRadius: '5px',
        marginBottom: '18px',
        paddingTop: '17px',
        paddingBottom: '17px',
        paddingLeft: '19px',
        paddingRight: '12px'
      })}
    >
      <Link
        to={`/checklists/category?planId=${planId}&categoryId=${category.id}`}
        className={css({
          textDecoration: 'none'
        })}
      >
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            ...theme.typography.ParagraphSmall
          })}
        >
          <div
            className={css({
              display: 'flex'
            })}
          >
            <div
              className={css({
                minWidth: '27px',
                marginRight: '13px',
                textAlign: 'center',
                alignSelf: 'center'
              })}
            >
              <FontAwesomeIcon
                icon={category.icon}
                color={copyColor}
                className={css({ fontSize: '21px' })}
              />
            </div>
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column'
              })}
            >
              <div>{category.title}</div>
              <div
                className={css({
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: '-2px'
                })}
              >
                {category.progression?.map((result, index) => {
                  const dotColor = result ? progressionColorMap[result] : ourColors.white
                  const coloredCircle = (
                    <span
                      key={`${category.id}-${index}`}
                      className={css({
                        height: '12px',
                        width: '12px',
                        backgroundColor: dotColor,
                        marginTop: '2px',
                        marginRight: '3px',
                        borderRadius: '50%',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: result ? dotColor : ourColors.lighterCopyGrey
                      })}
                    ></span>
                  )
                  return coloredCircle
                })}
              </div>
            </div>
          </div>
          <span
            className={css({
              marginLeft: '14px'
            })}
          >
            {category.completion}
          </span>
        </div>
      </Link>
    </div>
  )
}

interface CategoryListProps {
  categories: CategoryItem[]
  planId: string
}
export const CategoryList = ({ categories, planId }: CategoryListProps) => {
  const categoryBoxes = categories.map(category => (
    <Cell key={`category-${category.id}`} span={[4, 6]}>
      <CategoryBox category={category} planId={planId} />
    </Cell>
  ))
  return <Grid>{categoryBoxes}</Grid>
}

interface PlanPageProps {
  location: WindowLocation
}
export const PlanPage = (props: PlanPageProps) => {
  const { token, setUserContext } = React.useContext(UserContext)
  const planId = getQueryVariable('planId', props.location.search)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const [categories, setCategories] = React.useState<CategoryItem[] | null>(null)

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }

    if (!planId) {
      navigate('/404', { replace: true })
    }

    if (token && planId) {
      getCategories(token, planId)
        .then((response: CategoryListingResponse) => {
          if (response.categories) {
            setCategories(response.categories)
          } else {
            navigate('/404', { replace: true })
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, planId, setUserContext])

  const planTitleCopy = 'Select a category and measure your employer’s progress'
  const promptCopy = 'Please choose a category'

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} />
        </Cell>
      </Grid>
      <Title title={planTitleCopy} initialParagraph={promptCopy} />
      {categories && planId && <CategoryList planId={planId} categories={categories} />}
    </>
  )
}

interface LayoutPlanPageProps {
  location: WindowLocation
}
const LayoutPlanPage = (props: LayoutPlanPageProps) => {
  return (
    <Layout>
      <PlanPage location={props.location} />
    </Layout>
  )
}

export default LayoutPlanPage
